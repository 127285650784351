<template>
  <div v-loading="loading" :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
    :element-loading-background="config.loading.background">
    <div class="pl-31 fs-14 mt-12">
      <div>公司头像</div>
      <div class="dp-f mt-18 ai-c" v-loading="fileloading" :element-loading-background="config.loading.background">
        <img class="w-64 h-64 mr-12 br50" v-if="ruleForm.logoUrl" :src="ruleForm.logoUrl">
        <img class="w-64 h-64 mr-12 br50" v-else src="@/assets/img/general/enterprise.png">
        <!-- <el-upload ref="uploadRef" class="upload-demo" action="#" :http-request="ImgHttpImport"
          :accept='import_img_accept'>
      
        </el-upload> -->
            <oabutton CSStype=2 width=76 height=30 title="更换头像" @click="unref(EditAvatarRef).show()" ></oabutton>
      </div>
    </div>
    <el-form ref="ruleFormRef" label-position="top" class="mt-30 ml-31 w-500" :model="ruleForm" :rules="rules"
      label-width="120px">
      <el-form-item label="登录欢迎语" prop="welcomeMessage">
        <el-input v-model="ruleForm.welcomeMessage" :rows="2" type="textarea" />
      </el-form-item>
    </el-form>
    <div class="title mb-24 pl-31">
      主题设置
    </div>
    <div class="themes dp-f fw-w mb-38 pl-31">
      <div class="themebox w-144 h-108 cu-p" :class="state.themeIndex == ind ? 'themeac' : ''"
        v-for="(el, ind) in state.themeDatas" :key="ind" @click="setThemeClick(ind)">
        <img :src="el.url" class="w100 h100">
        <div class="mt-10">{{ el.title }}</div>
      </div>
    </div>
    <oabutton style="margin-top: 70px;" class="ml-31" CSStype=2 width=92 height=32 title="保存" @buttonclick="submitForm"
      v-model:loading="buttonloading"></oabutton>
        <EditAvatar ref="EditAvatarRef" @pushavatar="pushavatar"></EditAvatar>
  </div>
</template>

<script setup>
import { ref, unref, reactive,onUnmounted } from 'vue'
import { httpToken } from "@/utils/request";
import { import_img_accept } from "@/utils/base/accept"
import { getfileTypeName } from "@/utils/server/file.js"
import { uploadFile } from "@/utils/server/upload.js"
import {resetUserInfo} from "@/api/user/index"
import { handleMessage } from "@/utils/server/confirm";
import { setTheme,setTheme2 } from "@/utils/base/theme"
import EditAvatar from './avatar/neweditAvatar.vue'//修改头像弹框
import qs from "qs";
const EditAvatarRef = ref()//修改头像弹框ref
const loading = ref(false);// loading 显示
const fileloading = ref(false);//文件 loading 显示
const buttonloading = ref(false);//按钮 loading 显示
const uploadRef = ref()
const state = reactive({
  id: '',
  companyId: '',
  bgIndex: '1',//当前背景选中下标
  themeDatas: [
    { title:'默认主题',url:require("@/assets/img/settings/theme0.png") },
    { title:'商务蓝',url:require("@/assets/img/settings/theme1.png") },
    { title:'热情红',url:require("@/assets/img/settings/theme2.png") },
    { title:'科技橙',url:require("@/assets/img/settings/theme3.png") },
    { title:'炫酷黑',url:require("@/assets/img/settings/theme4.png") },
    { title:'清新黄',url:require("@/assets/img/settings/theme5.png") },
  ],//所有主题
  themeIndex: 0,//当前主题选中下标
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  logoUrl: '',
  welcomeMessage: '',//欢迎语
})
const rules = reactive({//from 表单校验

})
const setThemeClick=(ind)=>{
  state.themeIndex = ind
  // 主题预览功能
  setTheme2(ind)
}
// 图片上传方法
// const ImgHttpImport = ((rawFile) => {
//   let fileTypeName = getfileTypeName(rawFile.file.name)
//   let imgtype = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'bmp', 'BMP']
//   if (rawFile.size / 1024 / 1024 > 5) {
//     handleMessage('图片大小超过5MB')
//     resetFile()
//   } else if (imgtype.includes(fileTypeName)) {
//     fileloading.value = true
//     uploadFile(rawFile.file, "signinformationdefinition_file").then((res) => {
//       ruleForm.logoUrl = res.data.fileUrl
//       fileloading.value = false
//     }).catch(err => {
//       fileloading.value = false
//     })
//   } else {
//     handleMessage('请上传正确图片格式!')
//     resetFile()
//   }
// })
const pushavatar=(e)=>{
 ruleForm.logoUrl =e
}
const getData = () => {
  loading.value = true
  httpToken({
    method: "post",
    url: '/initiate-contract/signinformationdefinition/find',
  }).then((res) => {
    state.id = res.data.id
    state.companyId = res.data.companyId
    ruleForm.logoUrl = res.data.logoUrl
    ruleForm.welcomeMessage = res.data.welcomeMessage
    loading.value = false
  }).catch(() => {
    loading.value = false
  })
}
const getcolorData = (type=1) => {
  if(JSON.parse(window.sessionStorage.user).user_info.sysCompany.color){
    state.themeIndex=JSON.parse(window.sessionStorage.user).user_info.sysCompany.color
  }else{
    state.themeIndex=0
  }
  if(type==2)
    setTheme()
}
getcolorData()
getData()
// 表单提交
const submitForm = async () => {
  buttonloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    buttonloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      let p1=httpToken({
        method: "post",
        url: '/initiate-contract/signinformationdefinition/update',
        data: {
          ...ruleForm,
          companyId: state.companyId,
          id: state.id
        }
      })
      let p2=httpToken({
        method: "post",
        url: '/admin/sys-company/update',
        data: {
          companyId: JSON.parse(window.sessionStorage.user).user_info.sysCompany.companyId,
          // loginBackground:state.themeIndex,//登录背景图
          color: String(state.themeIndex),//登录颜色
        }
      })
      Promise.all([p1, p2]).then(() => {
        handleMessage('数据保存成功','success')
        resetUserInfo(()=>{
          getData()
          getcolorData(2)
        })
        buttonloading.value = false
      }).catch(() => {
        buttonloading.value = false
      })
    } else {
      buttonloading.value = false
    }
  })
}
onUnmounted(()=>{
  //还原登录主题色
  setTheme()
})
</script>
<style lang="scss" scoped>
.upload-demo {
  ::v-deep .el-upload-list {
    display: none !important;
  }
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.themes {
  .themebox+.themebox {
    margin-left: 24px;
  }

  .themeac {
    background: #FFFFFF;
      box-shadow: 0px 2px 9px 0px rgba(145,145,145,0.5);
      border-radius: 11px;
      padding: 4px;
  }
}
</style>